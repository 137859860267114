<template>
  <div class="mt-base">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="text-sm font-semibold">Approval</label>
        <ApprovalProgress
          ref="approvalProgress"
          type="PENGAJUAN-MEDIA"
          :id-ref="$route.params.idPengajuanMedia"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabApprovals',
  components: {
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  data () {
    return {}
  }
}
</script>
